<template>
	<div class="cashPage">
		<div class="banner_wrap">
			<div class="banner">
				<h1>
					<img
						src="../../../assets/img/fee/icon_arrow.png"
						width="24"
						height="24"
						alt=""
						@click="$router.go(-1)" />参与推广，获得收入
				</h1>
				<p>邀请好友成功付费，可以获得5%佣金返现</p>
			</div>
		</div>
		<div class="content_wrap">
			<div class="content">
				<div class="tabs" ref="tabs">
					<div
						@click="activeIndex = 0"
						:class="{ active: activeIndex == 0 }">
						提现
					</div>
					<div
						@click="activeIndex = 1"
						:class="{ active: activeIndex == 1 }">
						提现记录
					</div>
					<div
						class="line"
						:style="{
							width: `${lineWidth}px`,
							transform: `translateX(${offsetLeft}px)`,
						}"></div>
				</div>
				<div
					class="panels"
					ref="panels"
					:style="{ height: `${height}px` }">
					<transition name="left">
						<div class="getCash" v-if="activeIndex == 0">
							<a-row class="balance">
								<a-col :span="3">可提现金额</a-col>
								<a-col class="balance"
									><span>&yen;&nbsp;</span>{{ rebate }}</a-col
								>
							</a-row>
							<a-row class="cashAll">
								<a-col :span="3">提现金额</a-col>
								<a-col>
									<a-input
										addon-before="￥"
										v-model="money"></a-input>
									<span class="cashAll" @click="cashAll"
										>全部提现</span
									>
								</a-col>
							</a-row>
							<a-row>
								<a-col :span="3">提现方式</a-col>
								<a-col>
									<div class="getCash_style">
										<img
											src="../../../assets/img/fee/icon_cash.png"
											width="24"
											height="24"
											alt="" />
										<span>余额</span>
										<img
											src="../../../assets/img/fee/icon_selected.png"
											width="16"
											height="16"
											alt="" />
									</div>
								</a-col>
							</a-row>
							<a-row class="btn">
								<a-col :span="3"></a-col>
								<a-col>
									<a-button
										class="btn_getCash"
										@click="getCash"
										:loading="loading"
										>确定提现</a-button
									>
								</a-col>
							</a-row>
						</div>
					</transition>
					<transition name="right">
						<div class="record" v-if="activeIndex == 1">
							<a-table
								:row-key="(a, b) => b.toString()"
								:data-source="record"
								:columns="columns"
								:pagination="false">
								<span slot="method">余额</span>
								<span slot="account">{{ phone }}</span>
								<span slot="status">成功</span>
							</a-table>
							<a-pagination
								@change="pageChange"
								:total="total"
								:pageSize="10"></a-pagination>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { user_info } from "@/api/login";
import { getCash_aip, getCashRecord_api } from "@/api/invite";
const columns = [
	{
		title: "提现金额",
		dataIndex: "pay_amount",
	},
	{
		title: "提现方式",
		dataIndex: "method",
		scopedSlots: { customRender: "method" },
	},
	{
		title: "提现账号",
		dataIndex: "account",
		scopedSlots: { customRender: "account" },
	},
	{
		title: "提现时间",
		dataIndex: "payment_at",
	},
	{
		title: "状态",
		dataIndex: "status",
		scopedSlots: { customRender: "status" },
	},
];
export default {
	name: "CashPage",
	components: {},
	data() {
		return {
			activeIndex: 0,
			lineWidth: undefined,
			offsetLeft: undefined,
			money: "",
			rebate: 0,
			page: 1,
			total: 0,
			record: [],
			columns,
			phone: "",
			loading: false,
			height: 0,
		};
	},
	watch: {
		activeIndex: {
			handler(newVal) {
				this.$nextTick(() => {
					this.lineWidth =
						this.$refs.tabs.children[newVal].offsetWidth;
					this.offsetLeft =
						this.$refs.tabs.children[newVal].offsetLeft;
					this.height =
						30 + this.$refs.panels.children[newVal].clientHeight;
					console.dir(this.$refs.panels.children[newVal]);
				});
			},
			immediate: true,
		},
	},
	mounted() {
		this.getRecord();
		this.getInfo();
	},
	methods: {
		cashAll() {
			this.money = this.rebate;
		},
		getCash() {
			// 余额不足100
			if (this.rebate < 100) {
				return this.$message.error("余额不足100.00元，无法提现");
			}
			// 输入金额小于100
			if (this.money < 100) {
				return this.$message.error("单次提现金额不得低于100.00元");
			}
			// 输入金额大于佣金余额
			if (this.money > this.rebate) {
				return this.$message.error("提现金额不得大于佣金余额");
			}
			this.loading = true;
			getCash_aip({
				money: this.money,
			})
				.then(res => {
					if (res.data.code == 200) {
						this.$message.success("提现成功");
						this.getInfo();
						this.money = "";
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getRecord() {
			getCashRecord_api({
				page: this.page,
				pagesize: 10,
			}).then(res => {
				this.record = res.data.data.list;
				this.total = res.data.data.total;
			});
		},
		pageChange(e) {
			this.page = e;
			getCashRecord_api({
				page: this.page,
				pagesize: 10,
			}).then(res => {
				this.record = res.data.data.list;
				this.total = res.data.data.total;
			});
		},
		getInfo() {
			user_info({}).then(res => {
				if (res.data.code == 200) {
					this.rebate = res.data.data.member.rebate;
					this.phone = res.data.data.user.phone;
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
.cashPage {
	.banner_wrap {
		height: 140px;
		background-image: url(../../../assets/img/fee/cash_bg.png);
		background-size: 100% 100%;
		background-color: #3960f4;
		.banner {
			width: 1100px;
			margin: 0 auto;
			box-sizing: border-box;
			padding-top: 36px;

			h1 {
				font-size: 26px;
				font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
				font-weight: normal;
				color: #ffffff;
				line-height: 35px;
				margin: 0;
				img {
					vertical-align: middle;
					margin-right: 8px;
					cursor: pointer;
				}
			}
			p {
				padding-left: 32px;
				margin-top: 12px;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.8);
				line-height: 22px;
			}
		}
	}
	.content_wrap {
		.content {
			width: 1100px;
			margin: 0 auto;
			.tabs {
				height: 68px;
				box-sizing: border-box;
				border-bottom: 1px solid #e8e9ef;
				position: relative;
				display: flex;
				div {
					height: 100%;
					line-height: 68px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #252e3d;
					padding: 0 3px;
					cursor: pointer;
					transition: all 0.3s;
					&.active {
						color: #4c84ff;
					}
					&:hover {
						color: #4c84ff;
					}
					& + div {
						margin-left: 80px;
					}
					&.line {
						position: absolute;
						left: 0;
						bottom: 0;
						width: 20px;
						height: 3px;
						margin: 0;
						transition: all 0.3s;
						background-color: #4c84ff;
					}
				}
			}
			.panels {
				margin-top: 20px;
				position: relative;
				overflow: hidden;
				& > div {
					position: absolute;
					left: 0;
					top: 0;
					transition: all 0.3s;
					&.getCash {
						width: 100%;
						.ant-row {
							height: 60px;
							display: flex;
							align-items: center;
							.ant-col-3 {
								font-size: 16px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #252e3d;
								line-height: 22px;
								width: 11%;
							}
							.ant-col {
								display: flex;
								align-items: center;
								.ant-input-group-wrapper {
									margin-right: 12px;
								}
								span {
									white-space: nowrap;
								}
								.getCash_style {
									width: 136px;
									height: 52px;
									background: #f3f6fd;
									border-radius: 2px;
									border: 1px solid #4c84ff;
									margin-top: 30px;
									display: flex;
									align-items: center;
									box-sizing: border-box;
									padding: 0 10px 0 16px;
									position: relative;
									cursor: pointer;
									span {
										margin-left: 8px;
									}
									img {
										&:last-child {
											position: absolute;
											right: 10px;
										}
									}
								}
								.btn_getCash {
									width: 136px;
									height: 40px;
									background: #4c84ff;
									border-radius: 2px;
									line-height: 40px;
									text-align: center;
									font-size: 16px;
									font-family: PingFangSC-Medium, PingFang SC;
									font-weight: 500;
									color: #ffffff;
									cursor: pointer;
									transition: all 0.3s;
									&:hover {
										background-color: #3960f4;
									}
								}
							}
							&.btn {
								margin-top: 50px;
							}
							&.cashAll {
								.ant-col {
									& > .cashAll {
										color: #4c84ff;
										cursor: pointer;
										&:hover {
											color: #3960f4;
										}
									}
								}
							}
						}
						.balance {
							.balance {
								font-size: 20px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #252e3d;
								line-height: 28px;
							}
						}
					}
					&.record {
						width: 100%;
						.ant-pagination {
							margin-top: 30px;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							::v-deep .ant-pagination-item-active {
								background-color: #4c84ff;
								a {
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
	.left-enter,
	.left-leave-to {
		opacity: 0;
		transform: translateX(-100%);
	}
	.left-enter-to,
	.left-leave {
		opacity: 1;
		transform: translateX(0);
	}
	.right-enter,
	.right-leave-to {
		opacity: 0;
		transform: translateX(100%);
	}
	.right-enter-to,
	.right-leave {
		opacity: 1;
		transform: translateX(0);
	}
}
</style>
