import request from "@/utils/request";


// 获取邀请列表
export const getInviteList_api = (data)=>{
    return request({
        url: "/client_v1/invite/rebate-list",
        method: "POST",
        data,
    })
}

// 佣金提现
export const getCash_aip = (data) => {
    return request({
        url: "/client_v1/invite/withdraw",
        method: "POST",
        data
    })
}

// 佣金提现记录
export const getCashRecord_api = (data) => {
    return request({
        url: "/client_v1/invite/withdraw-log",
        method: "POST",
        data
    })
}