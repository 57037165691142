<template>
	<a-modal
		v-model="isshow"
		:width="840"
		:height="512"
		title="选择订单"
		@cancel="handleCancel"
		@ok="ok_handle">
		<span>订单号：</span>
		<a-input-search
			placeholder="请输入订单号搜索"
			style="width: 200px; margin-bottom: 20px"
			@search="go_onSearch" />
		<!-- :row-selection="rowSelection"  -->
		<a-table
			:row-selection="{
				selectedRowKeys: selectedRowKeys,
				onChange: onSelectChange,
			}"
			:loading="table_loading"
			:columns="columns"
			:data-source="listChoose"
			:row-key="record => record.id"
			:pagination="pagination"
			@change="handleTableChange">
			<div slot="cell_auth" slot-scope="text">未开票</div>
		</a-table>
	</a-modal>
</template>
<script>
import { user_choose } from "@/api/ballot.js";

export default {
	props: {
		auth_modalstatus: Boolean,
	},
	data() {
		return {
			isshow: this.auth_modalstatus,
			selectedRows: [], //表格选中单元
			selectedRowKeys: [],
			arrTow: [],
			listChoose: [],
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 4, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			// 查询参数
			queryParam: {
				page: "1", //第几页
				size: "4", //每页中显示数据的条数
				hosName: "",
				hosCode: "",
				province: "",
				city: "",
			},

			table_data: [],
			base_columns: [],
			table_loading: false,
			columns: [
				{
					title: "订单号",
					dataIndex: "trade_no",
					show: true,
					width: 255,
				},
				{
					title: "付款金额",
					dataIndex: "pay_amount",
					show: true,
					width: 90,
				},
				{
					title: "付款方式",
					dataIndex: "pay_channel",
					// scopedSlots: { customRender: "cell_depart" },
					show: true,
					width: 100,
				},
				{
					title: "订单时间",
					dataIndex: "payment_at",
					// scopedSlots: { customRender: "cell_auth" },
					show: true,
					width: 160,
				},
				{
					title: "开票状态",
					dataIndex: "cell_auth",
					scopedSlots: { customRender: "cell_auth" },
					show: true,
					width: 90,
				},
			],
		};
	},
	mounted() {
		this.getList();
	},
	computed: {},
	methods: {
		handleTableChange(pagination) {
			this.pagination.pageNum = pagination.current;
			this.getList();
		},
		onSelectChange(selectedRowKeys, selectedRows) {
			this.selectedRowKeys = selectedRowKeys;
		},
		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		go_onSearch(value) {
			this.getList(value);
		},
		async getList(value) {
			let { data } = await user_choose({
				trade_no: value,
				pagesize: 4,
				page: this.pagination.pageNum,
			});
			this.listChoose = data.data.list;
			this.arrTow[this.pagination.pageNum] = this.listChoose;

			this.pagination.total = data.data.total;
		},
		async ok_handle() {
			try {
				await this.$emit("orderId", this.selectedRowKeys, this.arrTow);
				this.isshow = false;
			} catch {}

			// let c_1 = [];
			// this.selectedRows.forEach((item) => {
			//   c_1.push(item.id)
			// });

			// let { data } = await user_invoice({

			// });
			// if (data.code == 200) {
			//   this.$message.success("授权成功");
			//
			//   this.$emit("success");
			// }
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-body {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 10px;
}

/deep/ .ant-table-body {
	height: 353px;
}

.top_wrap {
	display: flex;
	margin-bottom: 10px;
}

/deep/ .tab_envment_wrap {
	display: flex;
	height: 58px;
	width: 100%;

	.tab_envment_line {
		padding: 10px;
		width: 100px;
	}
}

/deep/ .ant-table-content {
	width: 786px;
}
</style>
