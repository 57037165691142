<template>
	<div class="member_wrap">
		<div class="content_right">
			<div class="content-heads">
				<div class="heads-left">
					<span>申请时间：</span>
					<a-date-picker
						:value="start_time"
						@change="onDataen"
						placeholder="请选择开始时间" />
					<span class="shenqi">申请时间：</span>
					<a-date-picker
						:value="end_time"
						@change="onDatadn"
						placeholder="请选择结束时间" />
					<span class="shenqi">开票类型：</span>
					<div>
						<a-select
							:value="typecal"
							style="width: 220px"
							placeholder="请选择开票类型"
							@change="onDatasa">
							<a-select-option :value="1">
								增值税普通发票
							</a-select-option>
							<a-select-option :value="2">
								增值税专用发票
							</a-select-option>
						</a-select>
					</div>
					<span class="shenqi">状态：</span>
					<div>
						<a-select
							:value="status"
							style="width: 220px"
							placeholder="请选择开票状态"
							@change="onDatasb">
							<a-select-option :value="1">
								待开票
							</a-select-option>
							<a-select-option :value="2">
								受理中
							</a-select-option>
							<a-select-option :value="3">
								已开票
							</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="heads-right">
					<button class="ca" @click="handle_search">查询</button>
					<button class="chong" @click="reset">重置</button>
				</div>
			</div>

			<div class="top_line">
				<div class="top_l_left">
					<a-button
						type="primary"
						class="top_btn"
						@click="open_addmember_pop">
						申请开票
					</a-button>

					<a-button
						class="top_btn"
						@click="open_editdepartment_pop()">
						开票信息设置
					</a-button>
				</div>

				<!-- <div class="top_l_right">
          <a-button class="top_btn"> 所有部门 </a-button>
        </div> -->
			</div>

			<div class="down_table">
				<a-table
					v-if="table_state"
					:loading="table_loading"
					:columns="columns"
					:data-source="table_data"
					:row-key="(r, i) => i.toString()"
					:pagination="pagination"
					@change="handleTableChange">
					<div slot="cell_id" slot-scope="text, r, i">
						0{{ i + 1 }}
					</div>

					<div slot="cell_status" slot-scope="text">
						{{ table_formate_status(text) }}
					</div>

					<div slot="cell_depart" slot-scope="text">
						{{ table_formate_depart(text) }}
					</div>
					<div slot="cell_tusa" slot-scope="text">
						{{ table_formate_tusa(text) }}
					</div>

					<div slot="operaTitle" class="title_operate">
						<div class="title_operate_left">操作</div>
					</div>

					<div
						slot="cell_operate"
						slot-scope="text, record"
						class="content_operate"
						v-if="record.role_id != 1">
						<div
							@click="open_editmember_pop(record)"
							class="cell_leftblue">
							{{ table_formate_aginc(record) }}
						</div>

						<div class="cell_blue" @click="showModal(record)">
							{{ table_formate_cell(record) }}
						</div>

						<div>
							<a-modal
								title="提示"
								:visible="visible"
								:confirm-loading="confirmLoading"
								@ok="handleOk"
								@cancel="handleCancel">
								<p>是否确定作废此发票？</p>
							</a-modal>
						</div>
					</div>
				</a-table>
			</div>
		</div>

		<add_member
			v-if="addmember_modalstatus"
			:modalstatus="addmember_modalstatus"
			:modaldata="ObjDing"
			@cancel="cancel_addmember"
			@success="success_addmember">
		</add_member>

		<edit_department
			v-if="editdepartment_modalstatus"
			:modalstatus="editdepartment_modalstatus"
			@cancel="cancel_editdepartment"
			@success="success_editdepartment">
		</edit_department>

		<edit_member
			v-if="editmember_modalstatus"
			:modaldata="check_data"
			:modalstatus="editmember_modalstatus"
			@cancel="cancel_editmember"
			@success="success_editmember">
		</edit_member>

		<auth_environment
			v-if="auth_modalstatus"
			:modaldata="check_data"
			:modalstatus="auth_modalstatus"
			@cancel="cancel_auth"
			@success="success_auth">
		</auth_environment>
		<!-- 
        <view_member v-if="view_modalstatus" :modaldata="check_data" :modalstatus="view_modalstatus"
            @cancel="cancel_view" @success="success_view">
        </view_member> -->
	</div>
</template>
<script>
// import moment from 'moment';
import add_member from "./components/add_member.vue";
import edit_department from "./components/edit_department.vue";
import edit_member from "./components/edit_member.vue";
import auth_environment from "../enterpriseManage/components/auth_environment.vue";
import { user_list, user_discard, user_getconfig } from "@/api/ballot.js";

import view_member from "../enterpriseManage/components/view_member.vue";
import { LOG10E } from "mathjs";
import { nextTick } from "process";
export default {
	name: "ballot_fee",
	components: {
		add_member,
		edit_department,
		edit_member,
		auth_environment,
		view_member,
	},
	data() {
		return {
			ModalText: "Content of the modal",
			visible: false,
			confirmLoading: false,
			start_time: "",
			end_time: "",
			typecal: undefined,
			status: undefined,
			applya: false,
			wrap_height: null, //wrap高度
			current: "allUser", //选中的目录
			batch_visibal: false, //批量气泡状态
			ObjDing: null, // 订单数据
			shall: {},
			//默认配置
			standard_config: {
				keywords: "",
				role_id: "",
				status: "",
				department_id: "",
				recent: "",
			},
			table_data: [],
			table_loading: false,
			pagination: {
				current: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},

			selectedRowKeys: [], //表格 选中单元序号
			selectedRows: [], //表格 选中单元行数组
			checked_columns: [], //自定义表格头
			columns: [
				{
					title: "序号",
					dataIndex: "cell_id",
					scopedSlots: { customRender: "cell_id" },
					// valu: index,
					show: true,
					width: 100,
					// render: (text, record, index) => index + 1,
				},
				{
					title: "申请时间",
					dataIndex: "create_time",
					show: true,
				},
				{
					title: "企业名称",
					dataIndex: "name",

					show: true,
				},
				{
					title: "开票金额",
					dataIndex: "money",

					show: true,
				},
				{
					title: "开票类型",
					dataIndex: "type",
					show: true,
					scopedSlots: { customRender: "cell_depart" },
				},
				{
					title: "发票性质",
					dataIndex: "style",
					scopedSlots: { customRender: "cell_status" },
					show: true,
				},
				{
					title: "申请人",
					dataIndex: "apply_truename",
					show: true,
				},
				{
					title: "状态",
					dataIndex: "status",
					scopedSlots: { customRender: "cell_tusa" },
					show: true,
				},

				{
					dataIndex: "operation",
					width: 250,
					fixed: "right",
					slots: { title: "operaTitle" },
					scopedSlots: { customRender: "cell_operate" },
					show: true,
				},
			],

			member_list: [], //成员列表
			role_list: [], //角色列表
			check_data: null, //选中成员

			addmember_modalstatus: false,
			editdepartment_modalstatus: false,
			editmember_modalstatus: false,
			auth_modalstatus: false,

			view_modalstatus: false,

			table_state: true,
		};
	},
	created() {},
	mounted() {
		this.set_wrap_height();
		this.get_tabledata();
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRows = selectedRows;
					this.selectedRowKeys = selectedRowKeys;
				},
				getCheckboxProps: record => ({
					props: {
						disabled: record.role_id === 1,
						name: record.username,
					},
				}),
			};
		},
	},
	methods: {
		showModal(e) {
			this.shall = e;
			this.visible = true;
		},
		async handleOk() {
			if (this.shall.status == 1) {
				let { data } = await user_discard({
					id: this.shall.id,
				});
				if (data.code == 200) {
					this.$message.success("作废成功！");
					this.visible = false;
					this.confirmLoading = false;
					this.get_tabledata();
					return;
				} else {
					this.$message.error("作废失败！");
				}
			} else if (record.status == 2) {
				return;
			} else if (record.status == 3) {
				return;
			} else if (record.status == 4) {
				return;
			}
		},
		async getConfig() {
			let { data } = await user_getconfig({});

			if (data.data.bank == "") {
				this.applya = false;
			} else {
				this.applya = true;
			}
		},
		handleCancel(e) {
			this.visible = false;
		},

		onDataen(date, dateString) {
			this.start_time = dateString;
		},
		onDatadn(date, dateString) {
			this.end_time = dateString;
		},
		onDatasa(date, dateString) {
			this.typecal = date;
		},
		onDatasb(date, dateString) {
			this.status = date;
		},
		//高度绑定为页面高度
		set_wrap_height() {
			this.wrap_height = document.body.clientHeight - 82;
		},

		//获得表格数据   list
		async get_tabledata() {
			this.table_loading = true;
			this.selectedRowKeys = [];
			let { data } = await user_list({
				start_time: this.start_time,
				end_time: this.end_time,
				type: this.typecal,
				status: this.status,
				pagesize: 20,
				page: this.pagination.pageNum,
			});
			this.ObjDing = data.data.list;
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;

				this.table_data = data.data.list;
			}
		},
		// 状态信息
		table_formate_tusa(data) {
			if (data == 1) {
				return "待开票";
			} else if (data == 2) {
				return "受理中";
			} else if (data == 3) {
				return "已开票";
			} else if (data == 4) {
				return "已废弃";
			}
		},
		table_formate_cell(data) {
			if (data.status == 1) {
				return "作废";
			} else if (data.status == 2) {
				return "";
			} else if (data.status == 3) {
				return "下载";
			} else if (data.status == 4) {
				return "";
			}
		},
		table_formate_aginc(data) {
			if (data.status == 1) {
				return "查看详情";
			} else if (data.status == 2) {
				return "查看详情";
			} else if (data.status == 3) {
				return "查看详情";
			} else if (data.status == 4) {
				return;
			}
		},
		//开票 普通或 专用
		table_formate_depart(data) {
			if (data == 1) {
				return "增值税普通发票";
			} else if (data == 2) {
				return "增值税专用发票";
			}
		},
		// //开票类型
		table_formate_status(data) {
			if (data == 1) {
				return "电子发票";
			} else if (data == 2) {
				return "邮寄";
			}
		},

		//selectedRowKeys
		//搜索回调
		handle_search() {
			this.get_tabledata();
		},
		reset() {
			this.start_time = "";
			this.end_time = "";
			this.typecal = undefined;
			this.status = undefined;
		},
		//表格 切换页码
		handleTableChange(pagination) {
			this.pagination.current = pagination.current;
			this.get_tabledata();
		},

		//申请开票
		open_addmember_pop() {
			this.getConfig();
			setTimeout(() => {
				if (this.applya) {
					this.addmember_modalstatus = true;
				} else {
					this.$message.error("请先设置开票信息！");
				}
			}, 1000);
		},
		cancel_addmember() {
			this.addmember_modalstatus = false;
		},
		success_addmember() {
			this.addmember_modalstatus = false;
			this.get_tabledata();
		},

		//编辑成员弹窗
		open_editmember_pop(record) {
			this.editmember_modalstatus = true;
			this.check_data = record;
		},
		cancel_editmember() {
			this.editmember_modalstatus = false;
		},
		success_editmember() {
			this.editmember_modalstatus = false;
			this.get_tabledata();
		},

		//授权弹窗 无用
		async open_auth_pop(record) {},
		cancel_auth() {
			this.auth_modalstatus = false;
		},
		success_auth() {
			this.auth_modalstatus = false;
			this.get_tabledata();
		},

		//查看用户弹窗
		open_view_pop(record) {
			this.view_modalstatus = true;
			this.check_data = record;
		},
		cancel_view() {
			this.view_modalstatus = false;
		},
		success_view() {
			this.view_modalstatus = false;
			this.get_tabledata();
		},

		//开票设置弹窗
		open_editdepartment_pop() {
			this.editdepartment_modalstatus = true;
		},
		cancel_editdepartment() {
			this.editdepartment_modalstatus = false;
		},
		success_editdepartment() {
			this.editdepartment_modalstatus = false;
			this.get_tabledata();
		},
	},
};
</script>

<style scoped lang="less">
.table-popover {
	.popover-content {
		width: 90px;
		padding-top: 8px;
		cursor: pointer;
	}

	.popover-content:hover {
		color: #4c84ff;
	}

	.popover_edit-content {
		width: 60px;
		padding-top: 5px;
		text-align: center;
		cursor: pointer;
	}

	.popover_edit-content:hover {
		color: #4c84ff;
	}
}

.member_wrap {
	min-width: 1624px;
	padding-left: 15px;
	margin: 0 auto;
	min-height: 600px;
	margin-top: 20px;

	//border: 1px solid green;
	display: flex;

	.content_left {
		padding-top: 38px;
		min-width: 210px;
		background-color: white;

		.menu_one {
			display: flex;
			flex-direction: row;
			height: 46px;

			.ant-menu-item-selected {
				background: linear-gradient(90deg, #f7f9ff 0%, #ebf0fc 100%);
			}

			.my_equipment {
				margin-top: 14px;
				margin-right: 7px;
				width: 18px;
				height: 16px;
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
			}

			.menu_txt_right_1 {
				background-image: url("../../../assets/img/member/成员@2x.png");
			}

			.menu_txt_right_2 {
				background-image: url("../../../assets/img/member/已禁用@2x.png");
			}

			.menu_txt_right_3 {
				background-image: url("../../../assets/img/member/成员审核@2x.png");
			}

			.title {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 46px;
			}

			.count {
				border: 1px solid #374567;
			}
		}
	}

	.content_right {
		background-color: white;
		flex: 1;
		margin-right: 20px;
		margin-left: 10px;

		.top_line {
			display: flex;
			justify-content: space-between;
			padding-top: 25px;

			.top_l_left {
				display: flex;

				.top_btn {
					margin-left: 15px;
				}

				.btn_search {
					margin-left: 15px;
					width: 450px;
					height: 30px;
				}
			}

			.top_l_right {
				display: flex;

				.top_btn {
					margin-left: 15px;
					margin-right: 20px;
				}
			}
		}

		.down_table {
			margin-top: 10px;

			.content_envname {
				color: #4c84ff;
			}

			.title_operate {
				display: flex;
				width: 200px;
				justify-content: space-between;
			}

			.content_operate {
				display: flex;

				.cell_blue {
					padding-left: 20px;
					color: #4c84ff;
					cursor: pointer;
				}

				.cell_leftblue {
					color: #4c84ff;
					cursor: pointer;
				}
			}
		}
	}
}

.content-heads {
	width: 100%;
	height: 30px;
	// border: 1px solid red;
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;

	.heads-left {
		width: 1268px;
		float: left;
	}

	.heads-right {
		float: right;
		// display: flex;
		// margin-left: 197px;

		.ca {
			width: 64px;
			height: 30px;
			background: #4c84ff;
			color: #fff;
			text-align: center;
			line-height: 30px;
			// float: right;
			border: 1px solid #4c84ff;
			cursor: pointer;
		}

		.chong {
			width: 64px;
			// float: right;
			height: 30px;
			background: #ffffff;
			border: 1px solid #4c84ff;
			color: #4c84ff;
			line-height: 30px;
			text-align: center;
			margin-left: 12px;
			cursor: pointer;
		}
	}

	div,
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #374567;
		line-height: 20px;
		display: inline-block;
		align-items: left;
	}

	.shenqi {
		margin-left: 18px;
	}
}

/deep/ .ant-modal-mask {
	background-color: rgba(145, 143, 143, 0);
}
</style>
