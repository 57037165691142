<template>
	<a-modal
		v-model="isshow"
		:width="650"
		title="开票信息"
		@cancel="handleCancel"
		@ok="ok_handle">
		<div class="line_wrap" style="margin-top: 0px">
			<div class="line_left red_title">企业名称：</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写发票抬头"
					v-model="objFrom.name" />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">企业税号:</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写税号"
					v-model="objFrom.tax_id" />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">开户行：</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写发票开户行"
					v-model="objFrom.bank" />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">开户账号：</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写发票开户账号"
					v-model="objFrom.bank_account" />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">注册地址：</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写企业注册地址"
					v-model="objFrom.register_address" />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">注册电话：</div>
			<div class="line_right">
				<input
					class="inputtt"
					placeholder="请填写企业注册电话"
					v-model="objFrom.register_phone" />
			</div>
		</div>
	</a-modal>
</template>
<script>
import moment from "moment";
import { user_subconfig, user_getconfig } from "@/api/ballot.js";
import { TreeSelect } from "ant-design-vue";

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
	props: {
		modalstatus: Boolean,
	},

	data() {
		return {
			isshow: false,
			objFrom: {
				tax_id: "",
				bank: "",
				bank_account: "",
				register_address: "",
				register_phone: "",
				name: "",
			},
			real_name: "",
			contact: "",
			code: 86,
			business_phone: null,
			business_pwd: "",
			role_id: 2,
			auth_method: 0,
			login_time: 0,
			begin_time: null,
			end_time: null,
			edit_info: 0,
			department_id: [],
			auth_modalstatus: true,
			radioStyle: {
				display: "inline-block",
				height: "30px",
				lineHeight: "30px",
			},

			replaceFields: {
				children: "children",
				title: "title",
				value: "id",
				key: "id",
			},
			selectedKeys: [],
			treeData: [],
			SHOW_PARENT,
		};
	},
	watch: {
		checkedKeys() {},
	},
	mounted() {
		this.isshow = this.modalstatus;
		this.Kicn();
	},
	methods: {
		async Kicn() {
			let { data } = await user_getconfig({});
			if (data.data != null) {
				this.objFrom = data.data;
			} else {
			}
		},
		moment,

		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		async ok_handle() {
			if (
				this.objFrom.tax_id != "" &&
				this.objFrom.bank != "" &&
				this.objFrom.bank_account != "" &&
				this.objFrom.register_address != "" &&
				this.objFrom.register_phone != "" &&
				this.objFrom.name != ""
			) {
				let { data } = await user_subconfig({
					tax_id: this.objFrom.tax_id,
					bank: this.objFrom.bank,
					bank_account: this.objFrom.bank_account,
					register_address: this.objFrom.register_address,
					register_phone: this.objFrom.register_phone,
					name: this.objFrom.name,
				});
				if (data.code == 200) {
					this.$message.success("创建成功");
					this.isshow = false;
					this.$emit("success");
				}
			} else {
				this.$message.error("开票信息填写完整！");
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-title {
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #2c354b;
	line-height: 25px;
}

/deep/ .ant-modal-body {
	padding-left: 98px;
	color: #374567;
}

/deep/ .line_wrap .line_left.red_title {
	width: 70px;
	// text-align: left;
}

/deep/ .ant-modal-footer {
	margin-top: 30px;
	border: none;
}

/deep/ .ant-modal-header {
	border: none;
	padding: 20px 24px;
}

/deep/ .ant-modal-body .line_wrap .line_right {
	width: 352px;
}

// .red_title::before {
//   display: inline-block;
//   margin-right: 4px;
//   color: #f5222d;
//   font-size: 14px;
//   font-family: SimSun, sans-serif;
//   line-height: 1;
//   content: "*";
// }
.inputtt {
	background: none;

	outline: none;

	border: 1px solid #ccc;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	transition: all 0.3s;
	width: 352px;
}
.line_wrap {
	display: flex;
	margin-top: 20px;

	.line_left {
		width: 140px;
		text-align: right;
		line-height: 32px;
	}

	.line_right {
		width: 430px;
		margin-left: 10px;
	}

	.text-din {
		width: 70px;
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 32px;
		cursor: pointer;
	}

	.text-yuan {
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ff8e43;
		line-height: 32px;
		cursor: pointer;
	}
}
</style>
