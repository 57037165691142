import request from "@/utils/request";
//设置开票默认
export const user_subconfig = (data) => {
    return request({
        url: "/client_v1/invoice/sub-config",
        method: "POST",
        data
    })
}
//获取开票默认配置信息
export const user_getconfig = (data) => {
    return request({
        url: "/client_v1/invoice/get-config",
        method: "POST",
        data
    })
}
//获取开票可选订单
export const user_choose = (data) => {
    return request({
        url: "/client_v1/invoice/choose-order",
        method: "POST",
        data
    })
}
//提交开票信息
export const user_invoice = (data) => {
    return request({
        url: "/client_v1/invoice/sub-invoice",
        method: "POST",
        data
    })
}

//获取开票列表
export const user_list = (data) => {
    return request({
        url: "/client_v1/invoice/list",
        method: "POST",
        data
    })
}

//获取开票详情
export const user_getinfo = (data) => {
    return request({
        url: "/client_v1/invoice/get-info",
        method: "POST",
        data
    })
}
//废弃开票申请
export const user_discard = (data) => {
    return request({
        url: "/client_v1/invoice/discard",
        method: "POST",
        data
    })
}


