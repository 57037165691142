<template>
	<a-modal
		v-model="isshow"
		:width="600"
		title="开票信息"
		@cancel="handleCancel"
		@ok="ok_handle">
		<div class="line_wrap" style="margin-top: 0px">
			<div class="line_left red_title">企业名称：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入企业名称"
					v-model="fromda.name"
					disabled />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">企业税号:</div>
			<div class="line_right">
				<a-input
					placeholder="请输入企业税号"
					v-model="fromda.tax_id"
					disabled />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">开户行：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入开户行"
					v-model="fromda.bank"
					disabled />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">开户账号：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入开户账号"
					v-model="fromda.bank_account"
					disabled />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">注册地址：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入注册地址"
					v-model="fromda.register_address"
					disabled />
			</div>
		</div>
		<div class="line_wrap">
			<div class="line_left red_title">注册电话：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入注册电话"
					v-model="fromda.register_phone"
					disabled />
			</div>
		</div>
		<!-- <div class="line_wrap">
      <div class="line_left">绑定登录手机号:</div>
      <div class="line_right">
        <a-input-group compact>
          <a-input style="width: 60px" v-model="code" />
          <a-input style="width: 370px" v-model="business_phone" />
        </a-input-group>
      </div>
    </div>

    <div class="line_wrap">
      <div class="line_left red_title">企业登录密码:</div>
      <div class="line_right">
        <a-input placeholder="请输入用企业登录密码" v-model="business_pwd" />
      </div>
    </div> -->

		<div class="line_wrap">
			<div class="line_left red_title">发票性质：</div>
			<div class="line_right">
				<div class="youji" v-if="fromda.style == 1">电子发票</div>
				<div class="youji" v-if="fromda.style == 2">邮寄</div>
			</div>
		</div>
		<div v-show="fromda.style == 2" class="line_wrap">
			<div class="line_left red_title">收件人：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入收件人姓名"
					v-model="fromda.receiver"
					disabled />
			</div>
		</div>
		<div v-show="fromda.style == 2" class="line_wrap">
			<div class="line_left red_title">联系方式：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入联系方式"
					v-model="fromda.contact_detail"
					disabled />
			</div>
		</div>
		<div v-show="fromda.style == 2" class="line_wrap">
			<div class="line_left red_title">收件地址：</div>
			<div class="line_right">
				<a-input
					placeholder="请输入收件地址"
					v-model="fromda.receive_address"
					disabled />
			</div>
		</div>

		<div class="line_wrap">
			<div class="line_left red_title">订单信息：</div>
		</div>
		<a-table
			:columns="columns"
			:data-source="ids"
			:row-key="(r, i) => i.toString()"
			@change="handleTableChange"
			:pagination="pagination">
			<div slot="cell_id" slot-scope="text, r, i">
				{{
					pagination.pageNum * pagination.pageSize -
					pagination.pageSize +
					i +
					1
				}}
			</div>
		</a-table>
		<div class="line_wrap">
			<div class="line_left red_title">发票信息：</div>
		</div>
		<a-table
			:columns="colstyle"
			:data-source="collist"
			:row-key="(r, i) => i.toString()"
			:pagination="false">
			<div slot="cell_id" slot-scope="text, r, i">
				{{
					pagination.pageNum * pagination.pageSize -
					pagination.pageSize +
					i +
					1
				}}
			</div>
			<div slot="cell_tusa" slot-scope="text">
				{{ table_formate_tusa(text) }}
			</div>
			<div slot="cell_depart" slot-scope="text">
				{{ table_formate_depart(text) }}
			</div>
			<div slot="cell_cao" slot-scope="text, r, i">
				<div class="cell_leftblue">查看</div>

				<div class="cell_blue">下载</div>
			</div>
		</a-table>
	</a-modal>
</template>
<script>
import moment from "moment";
import { user_getinfo, user_invoice } from "@/api/ballot.js";
import { TreeSelect } from "ant-design-vue";
import auth_environment from "./auth_environment.vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
	props: {
		modalstatus: Boolean,
		modaldata: Object,
	},
	components: {
		auth_environment,
	},
	data() {
		return {
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 3, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			isshow: false,
			fromda: {
				name: "",
				tax_id: "",
				bank: "",
				bank_account: "",
				register_address: "",
				register_phone: "",
			},
			real_name: "",
			order_id: [],
			collist: [],
			receiver: "",
			contact_detail: "",
			receive_address: "",
			contact: "",
			code: 86,
			business_phone: null,
			business_pwd: "",
			role_id: 2,
			auth_method: 1,
			login_time: 0,
			indexFpiao: 1,
			ids: [], // 选中的订单
			numMacth: 0.0, // 总金额
			auth_modalstatus: false, // 订单是否显示
			columns: [
				{
					title: "序号",
					dataIndex: "cell_id",
					scopedSlots: { customRender: "cell_id" },
					// valu: index,
					show: true,
					width: 20,
					// render: (text, record, index) => index + 1,
				},
				{
					title: "订单号",
					dataIndex: "trade_no",
					show: true,
					width: 180,
				},
				{
					title: "金额",
					dataIndex: "pay_amount",
					show: true,
					width: 50,
				},

				{
					title: "订单时间",
					dataIndex: "payment_at",
					show: true,
					width: 80,
				},
			],
			colstyle: [
				{
					title: "序号",
					dataIndex: "cell_id",
					scopedSlots: { customRender: "cell_id" },
					// valu: index,
					show: true,
					width: 50,
					// render: (text, record, index) => index + 1,
				},
				{
					title: "申请时间",
					dataIndex: "create_time",
					// valu: index,
					show: true,
					width: 130,
					// render: (text, record, index) => index + 1,
				},
				{
					title: "开票状态",
					dataIndex: "status",
					scopedSlots: { customRender: "cell_tusa" },
					show: true,
					width: 80,
				},
				{
					title: "发票金额",
					dataIndex: "money",
					show: true,
					width: 80,
				},

				{
					title: "发票类型",
					dataIndex: "type",
					scopedSlots: { customRender: "cell_depart" },
					show: true,
					width: 100,
				},
				{
					title: "操作",
					dataIndex: "cell_cao",
					scopedSlots: { customRender: "cell_cao" },
					show: true,
					width: 100,
					fixed: "right",
					height: 162,
				},
			],
			begin_time: null,
			end_time: null,
			edit_info: 0,
			department_id: [],

			radioStyle: {
				display: "inline-block",
				height: "30px",
				lineHeight: "30px",
			},

			replaceFields: {
				children: "children",
				title: "title",
				value: "id",
				key: "id",
			},
			selectedKeys: [],
			treeData: [],
			SHOW_PARENT,
		};
	},
	watch: {
		checkedKeys() {},
	},
	mounted() {
		this.isshow = this.modalstatus;
		this.get_departlist();
		this.collist.push(this.modaldata);
	},
	methods: {
		handleTableChange(pagination) {
			this.pagination.pageNum = pagination.current;
		},
		// 状态信息
		table_formate_tusa(data) {
			if (data == 1) {
				return "待开票";
			} else if (data == 2) {
				return "受理中";
			} else if (data == 3) {
				return "已开票";
			} else if (data == 4) {
				return "已废弃";
			}
		},
		//开票 普通或 专用
		table_formate_depart(data) {
			if (data == 1) {
				return "增值税普通发票";
			} else if (data == 2) {
				return "增值税专用发票";
			}
		},
		cancel_auth() {
			this.auth_modalstatus = false;
		},
		success_auth() {
			this.auth_modalstatus = false;
			this.get_tabledata();
		},
		xuanze() {
			this.auth_modalstatus = true;
		},
		handleChange(value) {
			this.indexFpiao = value;
		},
		moment,

		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		async get_departlist() {
			let { data } = await user_getinfo({
				id: this.modaldata.id,
			});
			if (data.code == 200) {
				this.fromda = data.data.invoice;
				this.ids = data.data.order;
			}
		},
		async ok_handle() {
			// if (this.department_id.length == 0) {
			//   this.$message.error('部门必选')
			//   return
			// }

			let { data } = await user_invoice({
				name: this.fromda.name,
				tax_id: this.fromda.tax_id,
				bank: this.fromda.bank,
				bank_account: this.fromda.bank_account,
				register_address: this.fromda.register_address,
				register_phone: this.fromda.register_phone,

				type: this.auth_method,
				order_id: this.order_id,
				style: this.indexFpiao,
				receiver: this.receiver,
				contact_detail: this.contact_detail,
				receive_address: this.receive_address,
			});
			if (data.code == 200) {
				this.$message.success("创建成功");
				this.isshow = false;
				this.$emit("success");
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-table-content {
	border: 1px solid #dedfe2;
}

/deep/ .ant-modal-body {
	padding-left: 40px;
	color: #374567;
}

/deep/ .ant-table-body {
	color: #374567;
}

/deep/ .ant-table-thead > tr > th {
	color: #374567;
}

/deep/ .ant-modal-body .line_wrap .line_right {
	width: 447px;
}

/deep/ .ant-modal-body .line_wrap .line_right {
	.youji {
		height: 32px;
		line-height: 32px;
		height: 32px;
		background: rgba(255, 255, 255, 0.9);
		border-radius: 3px;
		border: 1px solid;
		padding: 0px 11px;
		border-color: #d9d9d9;
		cursor: auto;
		color: #374567;
	}

	input {
		cursor: auto;
	}
}

/deep/ .ant-modal-header {
	border: none;
	padding: 20px 40px;
}

/deep/ .ant-table-thead > tr > th {
	background-color: #f2f3fa;
}

/deep/ .ant-modal-title {
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #2c354b;
	line-height: 25px;
}

/deep/ .line_wrap .line_left.red_title {
	width: 70px;
	// text-align: left;
}

.ant-input {
	background-color: #fff;
	color: #374567;
}

// .red_title::before {
//   display: inline-block;
//   margin-right: 4px;
//   color: #f5222d;
//   font-size: 14px;
//   font-family: SimSun, sans-serif;
//   line-height: 1;
//   content: "*";
// }
/deep/ .ant-table-wrapper {
	width: 527px;
}

/deep/ .ant-modal-footer {
	border: none;
}

.line_wrap {
	display: flex;
	margin-top: 20px;

	.line_left {
		width: 140px;
		text-align: right;
		line-height: 32px;
	}

	.line_right {
		width: 430px;
		margin-left: 10px;
	}

	.text-din {
		width: 70px;
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 32px;
		cursor: pointer;
	}

	.text-yuan {
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ff8e43;
		line-height: 32px;
		cursor: pointer;
	}
}

.cell_blue {
	padding-left: 10px;
	color: #4c84ff;
	cursor: pointer;
	display: inline-block;
}

.cell_leftblue {
	color: #4c84ff;
	cursor: pointer;
	display: inline-block;
}
</style>
